import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import { useLangFile } from 'js/context/LanguageContext';
import DraggableIntervals from 'js/components/Prescription/DraggableIntervals/DraggableIntervals';
import PrescriptionMergePopover from 'js/components/Prescription/Popovers/PrescriptionMergePopover';
import PrescriptionSplitPopover from 'js/components/Prescription/Popovers/PrescriptionSplitPopover';
import { usePrescriptionJob } from 'js/components/Prescription/PrescriptionJobContext';
import {
  calculateMergedIntervals,
  calculateSplitIntervals,
  getBufferPolygons,
} from 'js/components/Prescription/PrescriptionUtils';
import PrescriptionSplitViewContainer from 'js/components/Prescription/PrescriptionSplitView/PrescriptionSplitViewContainer';
import {
  PRESCRIPTION_OVERRIDE_MIN_BRUSH_SIZE,
  PRESCRIPTION_OVERRIDES_TOOL,
  PRESCRIPTION_UNIT,
} from 'js/constants/PrescriptionConstants';
import PrescriptionToolbar from '../../components/Prescription/PrescriptionToolbar/PrescriptionToolbar';
import { canvasToMap } from '../MapObjects/MapCanvas/MapCanvasClickable';
import * as turf from '@turf/turf';
import { SURVEY_LAYERS } from '../../constants/SurveyLayers';
import useFirebaseAnalytics, { FIREBASE_EVENTS } from '../../hooks/useFirebaseAnalytics';
import { iterateValidValues } from '../../helpers/SurveyUtils';
import { useSurveyLayerViewCapabilities } from '../../context/SurveyContext';
import ViewModeConstants from '../../constants/ViewModeConstants';
import CreateBufferDialog from '../Modals/CreateBufferDialog';
import { useMultipleJobs } from './PrescriptionJobContext';
import MultipleActionsDialog from '../Prescription/Dialogs/MultipleActionsDialog.jsx';
import { usePrescriptionEditorContext } from './PrescriptionEditorContext';

const roundBrushSize = (value) => {
  return (
    PRESCRIPTION_OVERRIDE_MIN_BRUSH_SIZE * Math.ceil(value / PRESCRIPTION_OVERRIDE_MIN_BRUSH_SIZE)
  );
};

const PrescriptionEditor = ({
  onExitSafe,
  onDownloadJob,
  onSetShowSettingsDialog,
  onSetShowAutoAdjustDialog,
  enableSurveys,
  enableNdvi,
  selectedField,
  setClassificationsReset,
  onDeleteFutureJob,
  onSaveJob,
  updatePrescriptionJob,
  onMergePrescriptionJobs,
  showSettingsDialog,
}) => {
  // General
  const analytics = useFirebaseAnalytics();
  const LangFile = useLangFile();

  const [showEditBuffer, setShowEditBuffer] = useState(false);

  // Prescription Job
  const { prescriptionJob, setPrescriptionJob } = usePrescriptionJob();
  const layerCapabilities = useSurveyLayerViewCapabilities(
    prescriptionJob.layer,
    ViewModeConstants.PRESCRIPTION
  );

  const { mapsState, selectedMap, setSelectedMap, updateMapState, mapPrescriptionBasedOn } =
    usePrescriptionEditorContext();

  const [variationsEnabled, setVariationsEnabled] = useState(layerCapabilities.enableVariations);
  const { showCurrentJob, hasMultipleJobs } = useMultipleJobs();
  const [_, setShowCurrentJob] = showCurrentJob;
  const [showHandleFutureJob, setShowHandleFutureJob] = useState(false);

  // Merging
  const [isMerging, setIsMerging] = useState(false);
  const [mergePopoverTarget, setMergePopoverTarget] = useState(null);
  const [mergePrimaryIndex, setMergePrimaryIndex] = useState(null);
  const [mergeSecondaryIndex, setMergeSecondaryIndex] = useState(null);

  // Splitting
  const [isSplitting, setIsSplitting] = useState(false);
  const [splitPopoverTarget, setSplitPopoverTarget] = useState(null);
  const [splitPrimaryIndex, setSplitPrimaryIndex] = useState(null);

  // Overrides
  const [isOverriding, setIsOverriding] = useState(false);
  const [tool, setTool] = useState(PRESCRIPTION_OVERRIDES_TOOL.OVERRIDE);
  const [savedOverrideBrushValue, setSavedOverrideBrushValue] = useState(null);
  const [overrideBrushValue, setOverrideBrushValue] = useState(75);
  const [overrideBrushSize, setOverrideBrushSize] = useState(10);

  // Buffers
  const [bufferWidth, setBufferWidth] = useState(30);
  const [bufferValue, setBufferValue] = useState(100);

  // implementing
  const [numberOfAddedViews, setnumberOfAddedViews] = useState(0);

  const handleDeleteFutureJob = useCallback(() => {
    setShowCurrentJob(true);
    setShowHandleFutureJob(false);
    onDeleteFutureJob();
  });

  const handleMergePrescriptionJobs = useCallback(() => {
    setShowCurrentJob(true);
    setShowHandleFutureJob(false);
    onMergePrescriptionJobs();
  });

  const onAddView = useCallback(() => {
    numberOfAddedViews < 1 ? setnumberOfAddedViews(numberOfAddedViews + 1) : null;
  });

  const onRemoveView = useCallback(() => {
    numberOfAddedViews > 0 ? setnumberOfAddedViews(numberOfAddedViews - 1) : null;
  });

  const enableSplittingOrMerging = () => {
    if (selectedMap === mapsState.leftMap.identifier) {
      return !(
        mapsState.leftMap.classificationsEnabled && layerCapabilities.enableCustomClassification
      );
    } else {
      return !(
        mapsState.centerMap.classificationsEnabled && layerCapabilities.enableCustomClassification
      );
    }
  };

  // Settings
  const onShowSettings = useCallback(() => {
    onSetShowSettingsDialog(true);
  }, []);

  const onShowEditBuffer = useCallback(() => {
    setShowEditBuffer(true);
  }, []);

  const onInitiateMerge = useCallback(() => {
    setIsMerging(true);
    analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_MERGE_INIT);
  }, []);

  const handleOnSplit = useCallback(
    (splitIndex) => {
      const changes = {
        intervals: calculateSplitIntervals(splitIndex, prescriptionJob.intervals),
      };

      setPrescriptionJob(changes, true);

      onReturnToEditor();
      analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_SPLIT_SUBMIT);
    },
    [prescriptionJob]
  );

  const onInitiateSplit = useCallback(() => {
    setIsSplitting(true);
    analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_SPLIT_INIT);
  }, []);

  const handleOnMerge = useCallback(
    (primaryIndex, secondaryIndex, option) => {
      const changes = {
        intervals: calculateMergedIntervals(
          primaryIndex,
          secondaryIndex,
          option,
          prescriptionJob.intervals
        ),
      };

      setPrescriptionJob(changes, true);

      onReturnToEditor();
      analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_MERGE_SUBMIT);
    },
    [prescriptionJob]
  );

  const onReturnToEditor = useCallback(() => {
    setMergePopoverTarget(null);
    setSplitPopoverTarget(null);
    setSplitPrimaryIndex(null);
    setMergeSecondaryIndex(null);
    setMergePrimaryIndex(null);
    setIsMerging((current) => {
      if (current) {
        analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_MERGE_CANCEL);
      }
      return false;
    });
    setIsSplitting((current) => {
      if (current) {
        analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_SPLIT_CANCEL);
      }
      return false;
    });
    setIsOverriding((current) => {
      if (current) {
        analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_OVERRIDES_BACK);
      }
      return false;
    });
  }, []);

  // Splitting and Merging Shared
  const onBarClicked = useCallback(
    (target, primaryIndex, secondaryIndex) => {
      if (isMerging && primaryIndex !== null && secondaryIndex !== null) {
        setMergePopoverTarget(target);
        setMergePrimaryIndex(primaryIndex);
        setMergeSecondaryIndex(secondaryIndex);
      }

      if (isSplitting && primaryIndex !== null) {
        setSplitPopoverTarget(target);
        setSplitPrimaryIndex(primaryIndex);
      }
    },
    [isMerging, isSplitting]
  );

  // Dragging
  const onDragValue = useCallback(
    (idx, value) => {
      const changes = {
        intervals: [...prescriptionJob.intervals],
      };
      changes.intervals[idx].prescription = value;

      setPrescriptionJob(changes, true);
    },
    [prescriptionJob]
  );

  const onDragInterval = useCallback(
    (idx, value) => {
      const changes = {
        intervals: [...prescriptionJob.intervals],
      };
      changes.intervals[idx].max = value;
      changes.intervals[idx + 1].min = value;

      setPrescriptionJob(changes, true);
    },
    [prescriptionJob]
  );

  const onToggleIsOverriding = useCallback(() => {
    setIsOverriding((prevState) => {
      if (!prevState) {
        analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_OVERRIDES_OPENED);
      }
      return !prevState;
    });
  }, []);

  const handleOnOverridesChanged = useCallback((newOverrides) => {
    const changes = {
      overrides: newOverrides,
    };

    setPrescriptionJob(changes, true);
  }, []);

  const onBufferUpdated = useCallback(
    (width, value) => {
      setBufferWidth(width);
      setBufferValue(value);

      setShowEditBuffer(false);

      if (selectedField && width >= 0) {
        const polygons = getBufferPolygons(selectedField, width);

        const values = prescriptionJob.values;
        const nextOverrides = prescriptionJob.overrides.map((row) => row.slice(0));

        // pre-calc turf polygons for bounds-checking
        const buffers = polygons.map((p) => turf.polygon([p]));
        const shouldOverride = (x, y) => {
          const { lat, lng } = canvasToMap(y, x, values, prescriptionJob.bounds, false);
          const point = turf.point([lng, lat]);

          for (let i = 0; i < buffers.length; i++) {
            if (turf.booleanContains(buffers[i], point)) {
              return false;
            }
          }

          return true;
        };

        iterateValidValues(values, (x, y) => {
          if (shouldOverride(x, y)) {
            const row = nextOverrides[y];
            if (row) {
              row[x] = value;
            }
          }
        });

        handleOnOverridesChanged(nextOverrides);
      }
    },
    [
      selectedField,
      prescriptionJob.values,
      prescriptionJob.overrides,
      handleOnOverridesChanged,
      prescriptionJob.bounds,
    ]
  );

  const onOverrideBrushSizeChanged = useCallback((event) => {
    setOverrideBrushSize(Math.round(Number(event.target.value)));
  }, []);

  const onOverrideBrushSizeBlur = useCallback(() => {
    setOverrideBrushSize((value) => roundBrushSize(value));
    analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_OVERRIDES_BRUSH_SIZE);
  }, []);

  const onOverrideBrushValueChanged = useCallback((event) => {
    setOverrideBrushValue(Number(event.target.value));
    analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_OVERRIDES_BRUSH_VALUE);
  }, []);

  const onToolChanged = useCallback(
    (event, newTool) => {
      if (!newTool) {
        return;
      }

      analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_OVERRIDES_TOOL, { newTool: newTool });
      if (newTool === PRESCRIPTION_OVERRIDES_TOOL.ERASER) {
        if (overrideBrushValue !== null) {
          if (overrideBrushValue > 0) {
            setSavedOverrideBrushValue(overrideBrushValue);
          }
          setOverrideBrushValue(-1);
        }
      }
      if (newTool === PRESCRIPTION_OVERRIDES_TOOL.OVERRIDE) {
        setOverrideBrushValue(savedOverrideBrushValue || 75);
        setSavedOverrideBrushValue(-1);
      }
      if (newTool === PRESCRIPTION_OVERRIDES_TOOL.EXCEPTION) {
        if (overrideBrushValue !== 0) {
          if (overrideBrushValue > 0) {
            setSavedOverrideBrushValue(overrideBrushValue);
          }
          setOverrideBrushValue(0);
        }
      }

      setTool(newTool);
    },
    [overrideBrushValue, savedOverrideBrushValue]
  );

  useEffect(() => {
    updateMapState('leftMap', 'sourceLayer', prescriptionJob.layer);
    updateMapState('centerMap', 'sourceLayer', prescriptionJob.layer);
  }, []);

  if (!prescriptionJob || !prescriptionJob.intervals || !prescriptionJob.values) {
    return null;
  }
  let unitString;
  switch (prescriptionJob.unit) {
    case PRESCRIPTION_UNIT.LITER:
      unitString = `${LangFile.PrescriptionSettings.unit.liters.short} / ha`;
      break;
    case PRESCRIPTION_UNIT.PIECES_M2:
      unitString = `${LangFile.PrescriptionSettings.unit.pieces.short} / m^2`;
      break;
    default:
      unitString = `${LangFile.PrescriptionSettings.unit.kilogram.short} / ha`;
      break;
  }

  return (
    <Box
      width={'100%'}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      alignItems={'center'}>
      <PrescriptionToolbar
        tool={tool}
        merging={isMerging}
        splitting={isSplitting}
        mergingEnable={enableSplittingOrMerging()}
        splittingEnable={enableSplittingOrMerging()}
        overriding={isOverriding}
        overrideBrushSize={overrideBrushSize}
        overrideBrushValue={overrideBrushValue}
        hasMultipleJobs={hasMultipleJobs}
        onEditBuffer={onShowEditBuffer}
        onExitSafe={onExitSafe}
        onDownloadJob={onDownloadJob}
        onToolChanged={onToolChanged}
        onShowSettings={onShowSettings}
        onInitiateSplit={onInitiateSplit}
        onInitiateMerge={onInitiateMerge}
        onReturnToEditor={onReturnToEditor}
        onToggleIsOverriding={onToggleIsOverriding}
        onOverrideBrushSizeBlur={onOverrideBrushSizeBlur}
        onSetShowAutoAdjustDialog={onSetShowAutoAdjustDialog}
        onOverrideBrushSizeChanged={onOverrideBrushSizeChanged}
        onOverrideBrushValueChanged={onOverrideBrushValueChanged}
        onHandleFutureJobClick={() => {
          setShowHandleFutureJob(true);
        }}
        onSaveJob={onSaveJob}
        numberOfAddedViews={numberOfAddedViews}
        onAddView={onAddView}
        onRemoveView={onRemoveView}
        selectedField={selectedField}
      />

      <MultipleActionsDialog
        open={showHandleFutureJob}
        title={LangFile.PrescriptionEditor.handleFuturePrescriptionDialog.title}
        message={LangFile.PrescriptionEditor.handleFuturePrescriptionDialog.message}
        onCancel={() => setShowHandleFutureJob(false)}
        primaryText={LangFile.PrescriptionEditor.handleFuturePrescriptionDialog.primaryText}
        onPrimary={handleMergePrescriptionJobs}
        secondaryText={LangFile.PrescriptionEditor.handleFuturePrescriptionDialog.secondaryText}
        onSecondary={handleDeleteFutureJob}
        secondaryColor="secondary"
      />

      <CreateBufferDialog
        open={showEditBuffer}
        initialWidth={bufferWidth}
        initialValue={bufferValue}
        unit={unitString}
        onUpdate={onBufferUpdated}
        onClose={() => {
          setShowEditBuffer(false);
        }}
      />

      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'flex-start'}
        alignItems={'center'}
        width={'100%'}
        height={'100%'}>
        <PrescriptionSplitViewContainer
          isOverriding={isOverriding}
          overrideBrushSize={overrideBrushSize}
          overrideBrushValue={overrideBrushValue}
          onOverridesChanged={handleOnOverridesChanged}
          setClassificationsReset={setClassificationsReset}
          enableSurveys={enableSurveys}
          enableNdvi={enableNdvi}
          updatePrescriptionJob={updatePrescriptionJob}
          numberOfAddedViews={numberOfAddedViews}
        />

        {!isOverriding && (
          <Fragment>
            <DraggableIntervals
              mergingIntervals={isMerging}
              splittingIntervals={isSplitting}
              mergePrimaryIndex={mergePrimaryIndex}
              mergeSecondaryIndex={mergeSecondaryIndex}
              classificationsEnabled={
                mapPrescriptionBasedOn === mapsState.leftMap.identifier
                  ? mapsState.leftMap.classificationsEnabled
                  : mapsState.centerMap.classificationsEnabled
              }
              variationsEnabled={
                mapPrescriptionBasedOn === mapsState.leftMap.identifier
                  ? mapsState.leftMap.variationsEnabled
                  : mapsState.centerMap.variationsEnabled
              }
              splittingIndex={splitPrimaryIndex}
              onBarClicked={onBarClicked}
              onDragValue={onDragValue}
              onDragInterval={onDragInterval}
              isCustomClassification={layerCapabilities.enableCustomClassification}
              showSettingsDialog={showSettingsDialog}
            />

            <PrescriptionMergePopover
              LangFile={LangFile}
              anchorEl={mergePopoverTarget}
              onCancel={onReturnToEditor}
              onMergeIntervals={handleOnMerge}
              primaryIndex={mergePrimaryIndex}
              secondaryIndex={mergeSecondaryIndex}
              intervals={prescriptionJob.intervals}
            />

            <PrescriptionSplitPopover
              LangFile={LangFile}
              anchorEl={splitPopoverTarget}
              onCancel={onReturnToEditor}
              onSplitInterval={handleOnSplit}
              splittingIndex={splitPrimaryIndex}
              intervals={prescriptionJob.intervals}
            />
          </Fragment>
        )}
      </Box>
    </Box>
  );
};

PrescriptionEditor.propTypes = {
  onExitSafe: PropTypes.func,
  onDownloadJob: PropTypes.func,
  onSetShowSettingsDialog: PropTypes.func,
  onSetShowAutoAdjustDialog: PropTypes.func,
  enableSurveys: PropTypes.bool,
  enableNdvi: PropTypes.bool,
  selectedField: PropTypes.object,
  setClassificationsReset: PropTypes.func,
  selectedLayer: PropTypes.string,
  onDeleteFutureJob: PropTypes.func,
  onSaveJob: PropTypes.func,
  updatePrescriptionJob: PropTypes.func,
  onMergePrescriptionJobs: PropTypes.func,
};

export default memo(PrescriptionEditor);
