import React, { memo, useCallback, useEffect, useState } from 'react';

import {
  changeImageType,
  selectField,
  setDate,
  setDates,
  setShowSatelliteImagery,
} from '../../reducers/FieldReducer';
import connect from 'react-redux/lib/connect/connect';
import MapLegend from './MapLegend';
import ViewModeConstants from '../../constants/ViewModeConstants';
import moment from 'moment-timezone';
import {
  setClassificationsEnabled,
  setShowSoilSurveys,
  setSelectedLayer,
  setStatisticsEnabled,
  setVariationsEnabled,
} from '../../reducers/SurveyReducer';
import { setNetworkSensor } from '../../reducers/WeatherNetworkReducer';
import DateHandler from '../DateHandler/DateHandler';
import { FIREBASE_EVENTS } from '../../hooks/useFirebaseAnalytics';
import { useFarm } from '../../context/AccountContext';
import { isSurveyLayer, isYieldLayer } from '../Prescription/PrescriptionJob';
import { OTHER_LAYERS } from '../../constants/OtherLayers';
import { YIELD_LAYER, DATA_INTEGRATION_YIELD } from '../../constants/YieldLayer';
import { setShowHeightMap } from '../../reducers/OverlayReducer';
import { ensureProperZoom } from '../../helpers/HeightMapUtils';
import { useGoogleMap } from '../../context/GoogleMapContext';
import { useHookRef } from '../../hooks/useHookRef';
import { useSelectedSeason } from '../../context/SeasonContext';
import { goToField } from '../../helpers/MapsUtils';

const findNearestDate = (date, dates, forward) => {
  let current = moment(date, 'YYYY-MM-DD').valueOf();

  let distances = dates
    .filter((d) => d !== date)
    .sort((a, b) => {
      const diffA = Math.abs(moment(a, 'YYYY-MM-DD').diff(current, 'hours'));
      const diffB = Math.abs(moment(b, 'YYYY-MM-DD').diff(current, 'hours'));
      return diffA - diffB;
    });

  return distances[0];
};

const mapStateToProps = (store) => {
  return {
    imageType: store.field.imageType,
    selectedField: store.field.selectedField,
    viewMode: store.control.viewMode,
    date: store.field.date,
    dates: store.field.dates,
    selectedFieldDates: store.field.selectedFieldDates,
    showSatelliteImagery: store.field.showSatelliteImagery,
    showSoilSurveys: store.survey.showSoilSurveys,
    surveys: store.survey.surveys,
    variationsEnabled: store.survey.variationsEnabled,
    classificationsEnabled: store.survey.classificationsEnabled,
    selectedLayer: store.survey.selectedLayer,
    statisticsEnabled: store.survey.statisticsEnabled,
    networkSensor: store.weatherNetwork.networkSensor,
    showWeatherNetwork: store.weatherNetwork.showWeatherNetwork,
    selectedSurveyReferenceValues: store.survey.selectedSurveyReferenceValues,
    selectedSurveyClassificationValues: store.survey.selectedSurveyClassificationValues,
    showHeightMap: store.overlay.showHeightMap,
    yieldData: store.yield.yieldData,
    dataIntegrations: store.integrations.dataIntegrations,
    fieldDates: store.field.fieldDates,
    fields: store.field.fields,
    selectField: store.field.selectField,
  };
};

const MapLegendContainer = ({
  dispatch,
  imageType,
  viewMode,
  date,
  dates,
  selectedFieldDates,
  showSatelliteImagery,
  showSoilSurveys,
  selectedLayer,
  networkSensor,
  showWeatherNetwork,
  classificationsEnabled,
  variationsEnabled,
  selectedField,
  surveys,
  statisticsEnabled,
  selectedSurveyReferenceValues,
  selectedSurveyClassificationValues,
  showHeightMap,
  yieldData,
  dataIntegrations,
  fieldDates,
  fields,
}) => {
  const [selectedFieldHasImage, setSelectedFieldHasImage] = useState(false);
  const [selectedFieldHasSurvey, setSelectedFieldHasSurvey] = useState(false);
  const [selectedFieldHasYield, setSelectedFieldHasYield] = useState(false);
  const [values, setValues] = useState(null);
  const farm = useFarm();
  const googleMapRef = useHookRef(useGoogleMap());
  const enableHeightmap = farm.cvr;
  const selectedSeason = useSelectedSeason();
  const enableYield = dataIntegrations.some(
    (d) =>
      d.name.toLowerCase() === DATA_INTEGRATION_YIELD &&
      d.connected &&
      Object.keys(yieldData).length > 0
  );

  const [yieldValuesColor, setYieldValuesColor] = useState(null);
  const [fieldJumpedTo, setFieldJumpedTo] = useState('');

  useEffect(() => {
    if (!showSoilSurveys && !showSatelliteImagery && !isYieldLayer(selectedLayer)) {
      if (surveys) {
        dispatch(setShowSoilSurveys(true));
        dispatch(setShowSatelliteImagery(false));
      } else if (dates.length > 0) {
        dispatch(setShowSoilSurveys(false));
        dispatch(setShowSatelliteImagery(true));
      } else {
        dispatch(setShowSoilSurveys(false));
        dispatch(setShowSatelliteImagery(false));
      }
    }
  }, [surveys, showSoilSurveys, showSatelliteImagery]);

  useEffect(() => {
    if (selectedLayer === YIELD_LAYER.YIELD) {
      let dates = [];
      let fieldsWithYieldData = {};
      for (const fieldId in yieldData) {
        if (yieldData.hasOwnProperty(fieldId)) {
          const fieldData = yieldData[fieldId];
          fieldData.forEach((obj) => {
            if (obj.date && fields.get(Number(fieldId))) {
              dates.push(obj.date);
              fieldsWithYieldData[fieldId] = fields.get(Number(fieldId));
            }
          });
        }
      }
      dates.sort((a, b) => new Date(a) - new Date(b));
      dispatch(setDates(dates));
      dispatch(setDate(dates[dates.length - 1]));
    } else {
      dispatch(setDates(fieldDates));
      dispatch(setDate(fieldDates[fieldDates.length - 1]));
    }
  }, [selectedLayer, fields]);

  const onFieldClicked = useCallback((field) => {
    goToField(googleMapRef.current, field);
    dispatch(selectField(field));
  }, []);

  useEffect(() => {
    if (!isYieldLayer(selectedLayer)) return;
    selectedField != null ? setFieldJumpedTo(selectedField) : setFieldJumpedTo('');
  }, [selectedField]);

  useEffect(() => {
    setSelectedFieldHasImage(
      Boolean(selectedFieldDates && date && selectedFieldDates.includes(date))
    );
  }, [selectedFieldDates, date]);

  useEffect(() => {
    setSelectedFieldHasSurvey(
      Boolean(surveys && selectedField && surveys.find((s) => s.fieldId === selectedField.fieldId))
    );
  }, [selectedField, surveys]);

  useEffect(() => {
    setSelectedFieldHasYield(
      Boolean(yieldData && selectedField && yieldData[selectedField.fieldId] != null)
    );
  }, [selectedField, yieldData]);

  useEffect(() => {
    if (selectedFieldHasYield && isYieldLayer(selectedLayer)) {
      let yieldValues = yieldData[selectedField.fieldId];
      if (yieldValues) {
        setYieldValuesColor(yieldValues[0].colorIntervals);
      }
    }
    if (!selectedFieldHasYield) {
      setYieldValuesColor(null);
    }
  }, [selectedFieldHasYield, date]);

  useEffect(() => {
    if (showSoilSurveys) {
      if (farm.cvr == null) {
        // non-Danish customers
        if (isSurveyLayer(selectedLayer)) {
          dispatch(setClassificationsEnabled(false));
        }
      }
    }
  }, [showSoilSurveys, selectedLayer, farm]);

  useEffect(() => {
    if (selectedSurveyReferenceValues && selectedLayer) {
      setValues(selectedSurveyReferenceValues[selectedLayer]);
    }
  }, [selectedLayer, selectedSurveyReferenceValues]);

  const onSetSatelliteImageType = useCallback(
    (type) => {
      dispatch(setSelectedLayer(type));
      dispatch(changeImageType(type));

      if (!showSatelliteImagery) {
        dispatch(setShowSatelliteImagery(true));
      }

      dispatch(setShowSoilSurveys(false));
    },
    [showSatelliteImagery]
  );

  const onSetSurveyType = useCallback(
    (type) => {
      dispatch(setSelectedLayer(type));

      if (!showSoilSurveys) {
        dispatch(setShowSoilSurveys(true));
      }

      dispatch(setShowSatelliteImagery(false));
    },
    [showSoilSurveys]
  );

  const handleSetNetworkSensor = useCallback((sensor) => {
    dispatch(setNetworkSensor(sensor));
  }, []);

  useEffect(() => {
    if (
      viewMode === ViewModeConstants.ANALYSIS &&
      selectedFieldDates &&
      selectedFieldDates.length > 0
    ) {
      let index = selectedFieldDates.indexOf(date);

      if (index === -1) {
        let nearestDate = findNearestDate(date, selectedFieldDates, true);
        dispatch(setDate(nearestDate));
      }
    }
  }, [viewMode, date, dates, selectedFieldDates]);

  useEffect(() => {
    if (viewMode === ViewModeConstants.OVERVIEW) {
      if (variationsEnabled) {
        dispatch(setVariationsEnabled(false));
      }
    }
  }, [viewMode, variationsEnabled]);

  const handleDateSet = useCallback((date) => {
    let dateComponent = moment(date).format('YYYY-MM-DD');
    dispatch(setDate(dateComponent));
  }, []);

  const onVariationsEnabledChanged = useCallback(
    (value) => {
      dispatch(setVariationsEnabled(value));
      if (classificationsEnabled && value) {
        dispatch(setClassificationsEnabled(false));
      }
    },
    [classificationsEnabled]
  );

  const onClassificationsEnabledChanged = useCallback(
    (value) => {
      dispatch(setClassificationsEnabled(value));
      if (variationsEnabled && value) {
        dispatch(setVariationsEnabled(false));
      }
    },
    [variationsEnabled]
  );

  const onStatisticsEnabledChanged = useCallback((value) => {
    dispatch(setStatisticsEnabled(value));
  }, []);

  const handleOtherTypeSelected = useCallback((layer, value) => {
    if (layer === OTHER_LAYERS.HEIGHT_MAP) {
      if (value) {
        ensureProperZoom(googleMapRef.current).then(() => {
          dispatch(setShowHeightMap(true));
        });
      } else {
        dispatch(setShowHeightMap(false));
      }
    }
    if (layer === YIELD_LAYER.YIELD) {
      dispatch(setSelectedLayer(layer));
      dispatch(setShowSatelliteImagery(false));
      dispatch(setShowSoilSurveys(false));
    }
  }, []);

  return (
    <MapLegend
      viewMode={viewMode}
      enableHeightmap={enableHeightmap}
      onOpenFirebaseEvent={
        viewMode === ViewModeConstants.OVERVIEW
          ? FIREBASE_EVENTS.OVERVIEW_ML_OPEN
          : FIREBASE_EVENTS.ANALYSIS_ML_OPEN
      }
      showSatelliteImagery={showSatelliteImagery}
      showSoilSurveys={showSoilSurveys}
      selectedFieldHasImage={selectedFieldHasImage}
      selectedFieldHasSurvey={selectedFieldHasSurvey}
      selectedLayer={showSatelliteImagery ? imageType : selectedLayer}
      networkSensor={networkSensor}
      networkEnabled={showWeatherNetwork}
      dates={dates}
      values={values}
      surveys={surveys}
      variationsEnabled={variationsEnabled}
      classificationsEnabled={classificationsEnabled}
      onSetNetworkSensor={handleSetNetworkSensor}
      onSetSatelliteImageType={onSetSatelliteImageType}
      onSetSurveyType={onSetSurveyType}
      onVariationsEnabledChanged={onVariationsEnabledChanged}
      onClassificationsEnabledChanged={onClassificationsEnabledChanged}
      statisticsEnabled={statisticsEnabled}
      onStatisticsEnabledChanged={onStatisticsEnabledChanged}
      renderDateHandler={() => (
        <DateHandler
          date={date}
          dates={viewMode === ViewModeConstants.ANALYSIS ? selectedFieldDates : dates}
          handleDateSet={handleDateSet}
        />
      )}
      disableDemandClassification={viewMode === ViewModeConstants.OVERVIEW}
      surveyClassificationValues={selectedSurveyClassificationValues}
      showHeightMap={showHeightMap}
      onSelectOtherType={handleOtherTypeSelected}
      selectedFieldHasYield={selectedFieldHasYield}
      yieldValuesColor={yieldValuesColor}
      enableYield={enableYield}
      onFieldClicked={onFieldClicked}
      fieldJumpedTo={fieldJumpedTo}
    />
  );
};

export default memo(connect(mapStateToProps)(MapLegendContainer));
