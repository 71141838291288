'use strict';

import axios from 'axios';
import { saveData } from 'js/DownloadFile';
import TokenHandler from 'js/TokenHandler';

import moment from 'moment-timezone';

import NetworkSensor from 'js/model/network/NetworkSensor';
import { getPlatformInfo } from 'js/helpers/BrowserDetection';
import {
  isSatelliteLayer,
  isSurveyLayer,
  PrescriptionJob,
  PrescriptionJobPostBody,
  PrescriptionJobPutBody,
  isYieldLayer,
  Layer,
} from 'js/components/Prescription/PrescriptionJob';
import { RAIN_ACCUMULATION } from './constants/WeatherConstants';
import ManualFeatureConstants from 'js/constants/ManualFeatureConstants';
import { mapInternationalSurveyValueToLocal } from './context/AppSettings/SurveyFormatConverter';
import { Formats } from './components/Prescription/Downloading/PrescriptionDownloader';

const tokenHandler = new TokenHandler();

const kanisa = tokenHandler.createKanisaClient();
const unauthorizedClient = tokenHandler.createUnauthorizedClient();

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

export const getDeviceInfoHeader = () => {
  const platformInfo = getPlatformInfo();
  const encoded = btoa(JSON.stringify(platformInfo));

  if (process.env.NODE_ENV === 'development') {
    return {};
  }

  return { 'fs-deviceinfo': encoded };
};

export const delayedPromise = (promise, delayMillis) => {
  const start = moment();

  return new Promise(async (resolve, reject) => {
    try {
      const result = await promise;

      const now = moment();
      const elapsed = moment.duration(now.diff(start)).as('milliseconds');

      if (elapsed < delayMillis) {
        setTimeout(() => resolve(result), delayMillis - elapsed);
      } else {
        resolve(result);
      }
    } catch (e) {
      reject(e);
    }
  });
};

const WebAPIUtils = {
  cancelAllRequest: function () {
    source.cancel('Requests cancelled.');
  },

  forceRefreshToken: function () {
    return tokenHandler.refreshAccessTokenPromise();
  },

  getOutlines: function (north, east, south, west) {
    return kanisa.get('outlines', {
      cancelToken: source.token,
      params: { north: north, east: east, south: south, west: west },
    });
  },

  getWFSOutlines: function (north, east, south, west, countryCode) {
    return kanisa.get(`outlines/${countryCode}`, {
      cancelToken: source.token,
      params: { north: north, east: east, south: south, west: west },
    });
  },

  getFarmUsers: function (farmId) {
    return kanisa.get('farms/' + farmId + '/users');
  },

  getFarms: function (userId) {
    return kanisa.get(`users/${userId}/farms`, {
      cancelToken: source.token,
    });
  },

  addUserToFarm: function (farmId, userObject) {
    return kanisa.post('farms/' + farmId + '/users', userObject, {
      cancelToken: source.token,
    });
  },

  removeUserFromFarm: function (userId, farmId) {
    return kanisa.delete('farms/' + farmId + '/users/' + userId, {
      cancelToken: source.token,
    });
  },

  getUser: function (token) {
    return unauthorizedClient.get('users', {
      cancelToken: source.token,
      headers: {
        Authorization: token,
      },
    });
  },

  getUserInfo: function () {
    return kanisa.get('users', {
      cancelToken: source.token,
    });
  },

  getUserToken: function (username, password) {
    return unauthorizedClient.post(
      'users/authenticate',
      { username, password },
      {
        cancelToken: source.token,
        headers: {
          ...getDeviceInfoHeader(),
        },
      }
    );
  },

  getFarmToken: function (farmId) {
    return kanisa.post('farms/' + farmId + '/authenticate', null, {
      cancelToken: source.token,
    });
  },

  impersonateUser: function (userId) {
    return kanisa.post('users/' + userId + '/impersonate');
  },

  getFarmFields: function (farmId) {
    return kanisa.get('fields', {
      cancelToken: source.token,
      params: {
        userPackageId: farmId,
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  addFields: function (outlinesList) {
    return kanisa.post('fields', outlinesList, {
      cancelToken: source.token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  deleteField: function (fieldId) {
    return kanisa.delete('fields/' + fieldId, {
      cancelToken: source.token,
    });
  },

  getDates: function (farmId) {
    return kanisa.get('farms/' + farmId + '/images/dates', {
      cancelToken: source.token,
    });
  },

  getCropHealthForDate: function (farmId, date) {
    return kanisa.get('farms/' + farmId + '/images', {
      cancelToken: source.token,
      params: { date },
    });
  },

  putFieldName: function (name, fieldId) {
    return kanisa.put('fields/' + fieldId + '/name', name, {
      cancelToken: source.token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  putPassword: function (userId, pswd) {
    return kanisa.put('users/' + userId + '/password', pswd, {
      cancelToken: source.token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  passwordResetRequest: function (payload) {
    return kanisa.post('users/initiate-password-reset', payload, {
      cancelToken: source.token,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  postNote: function (note) {
    return kanisa.post('notes', note, {
      cancelToken: source.token,
    });
  },

  putNote: function (note) {
    return kanisa.put('notes', note, {
      cancelToken: source.token,
    });
  },

  deleteNote: function (noteId) {
    return kanisa.delete('notes/' + noteId, {
      cancelToken: source.token,
    });
  },

  getAllNotes: function (farmId) {
    return kanisa.get(`farms/${farmId}/notes`, {
      cancelToken: source.token,
    });
  },

  /**
   * Fetches an array of statistics objects for each date that the bundle (specified with bundleId) has an image.
   * @param {int} bundleId Is the id of the bundle to fetch data for
   * @param {string} type Is the type of stats to fetch. This should be either ndvi or ndre.
   * @return {Promise} a promise resolving to the data when ready is returnd
   */
  getBundleStats: function (bundleId, type) {
    return kanisa.get('bundles/' + bundleId + '/stats', {
      cancelToken: source.token,
      params: { type },
    });
  },

  getFarmSettings: function (farmId) {
    return kanisa.get('farms/' + farmId + '/settings', {
      cancelToken: source.token,
    });
  },

  putFarmSetting: function (farmId, payload) {
    return kanisa.put('farms/' + farmId + '/settings', payload, {
      cancelToken: source.token,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  getUserSettings: function (userId) {
    return kanisa.get('users/' + userId + '/settings', {
      cancelToken: source.token,
    });
  },

  putUserSetting: function (userId, payload) {
    return kanisa.put('users/' + userId + '/settings', payload, {
      cancelToken: source.token,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  putLanguage: function (language) {
    return kanisa.put('users/me/language', {
      language: language,
    });
  },

  fetchWeatherStations: function (farmId, accumulation) {
    let rainSince;

    if (accumulation === RAIN_ACCUMULATION.SINCE_MIDNIGHT) {
      rainSince = moment().startOf('day').toISOString();
    } else {
      rainSince = moment().subtract(24, 'h').toISOString();
    }

    return kanisa.get(`meteor/devices?farmId=${farmId}&rainSince=${rainSince}`, {
      cancelToken: source.token,
    });
  },

  fetchReadings: function (id, farmId, periodStart, periodEnd, resolution, sensor) {
    const since = moment(periodStart).toISOString();
    const until = moment(periodEnd).toISOString();
    const timezone = moment.tz.guess();

    let url = `meteor/devices/${id}/measurements?farmId=${farmId}&sensor=${sensor}&since=${since}&until=${until}`;

    if (resolution) {
      url += `&resolution=${resolution}`;
    }

    url += `&timezone=${timezone}`;

    return kanisa.get(url);
  },

  fetchForecastReadings: function (id, farmId, periodStart, periodEnd, resolution, sensor) {
    const since = moment(periodStart).toISOString();
    const until = moment(periodEnd).toISOString();
    const timezone = moment.tz.guess();

    let url = `meteor/devices/${id}/forecast/history2?farmId=${farmId}&sensor=${sensor}&since=${since}&until=${until}&timezone=${timezone}`;

    if (resolution) {
      url += `&resolution=${resolution}`;
    }

    return kanisa.get(url);
  },

  getFarmPermissions: function (id) {
    return kanisa.get('farms/' + id + '/features', {
      cancelToken: source.token,
    });
  },

  /* ============== //
  ||      VRM       ||
  // ============== */

  // remove images when api is done
  getVariableRateMap: async function (mapId) {
    return await kanisa.get(`vrm/${mapId}`);
  },

  fetchFieldNdviValues: function (fieldId, satellite, dates) {
    let url = 'fields/' + fieldId + '/ndvi';
    const params = [];

    if (satellite) {
      params.push('satellite=' + satellite);
    }

    if (dates) {
      params.push('dates=' + dates.map((d) => moment(d).format('YYYY-MM-DD')).join(','));
    }

    if (params.length > 0) {
      url += '?';
      url += params.join('&');
    }

    return kanisa.get(url, {
      cancelToken: source.token,
    });
  },

  postVariableRateMap: function (fieldId, map) {
    return kanisa.post(`fields/${fieldId}/vrm`, map, {
      cancelToken: source.token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  putVariableRateMap: function (mapId, map) {
    return kanisa.put(`vrm/${mapId}`, map, {
      cancelToken: source.token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  deleteVariableRateMap: function (mapId) {
    return kanisa.delete(`vrm/${mapId}`, {
      cancelToken: source.token,
    });
  },

  deleteVariableRateMapLegacy: function (farmId, mapId) {
    return kanisa.delete(`farms/${farmId}/vrm-legacy/${mapId}`, {
      cancelToken: source.token,
    });
  },

  getAllSoilSamples: function (farmId) {
    return kanisa.get(`farms/${farmId}/soil`, {
      cancelToken: source.token,
    });
  },

  fetchSoilSampleFile: function (sampleId) {
    return kanisa({
      url: `soil/${sampleId}/file`,
      method: 'GET',
      responseType: 'blob', // Important
    });
  },

  postSoilSampleZip: function (file, fileName, source, farmId, progressCallback) {
    const data = new FormData();

    data.append('sample', file, file.name);

    data.append(
      'body',
      new Blob(
        [
          JSON.stringify({
            name: fileName,
            source: source,
            size: file.size,
          }),
        ],
        {
          type: 'application/json',
        }
      )
    );

    return kanisa.post(`farms/${farmId}/soil`, data, {
      cancelToken: source.token,
      onUploadProgress: (ProgressEvent) => {
        progressCallback((ProgressEvent.loaded / ProgressEvent.total) * 100);
      },
    });
  },

  fetchWeatherRegion: function (farmId, sensor, level, bounds) {
    let sensorParam = sensor;
    if (sensor === NetworkSensor.RAIN_ACC) {
      sensorParam = NetworkSensor.RAIN_24H;
    }

    const { n, s, e, w } = bounds;
    const boundsParam = `&north=${n}&east=${e}&south=${s}&west=${w}`;

    return kanisa.get(
      `meteor/network/readings?farmId=${farmId}&sensor=${sensorParam}&level=${level}${boundsParam}`,
      {
        cancelToken: source.token,
      }
    );
  },

  fetchNews: function (farmId, language) {
    const platform = 'web';
    const lang = language ? language : 'en';

    return kanisa.get(`news?farmId=${farmId}&platform=${platform}&language=${lang}`, {
      cancelToken: source.token,
    });
  },

  claimDevice: function (farmId, label, since) {
    return kanisa.post(`meteor/devices?farmId=${farmId}`, {
      label,
      since,
    });
  },

  replaceWeatherStation: function (id, farmId, replacementId, dismountDate, mountDate) {
    const body = {
      replacementId: replacementId,
      retiredSince: dismountDate,
    };

    if (ManualFeatureConstants.OVERRIDE_CLAIMED_SINCE) {
      body.claimedSince = mountDate;
    }

    return kanisa.post(`meteor/devices/${id}/replace?farmId=${farmId}`, body);
  },

  retireWeatherStation: function (id, farmId, since) {
    return kanisa.post(`meteor/devices/${id}/retire?farmId=${farmId}`, {
      since,
    });
  },

  getAllSurveys: function (farmId) {
    return kanisa.get(`farms/${farmId}/surveys`);
  },

  getSurveyLayer: function (farmId, surveyId, layer, type) {
    let url = `farms/${farmId}/surveys/${surveyId}/layers?layer=${layer}`;
    if (type) {
      url = url.concat(`&type=${type}`);
    }
    return kanisa.get(url, {
      headers: {
        Accept: 'application/json',
      },
    });
  },

  getYieldData: function (farmId, fieldId) {
    const url = 'farms/' + farmId + '/exatrek/assets/' + fieldId;

    return kanisa.get(url, {
      cancelToken: source.token,
    });
  },

  getAllYieldData: function (farmId) {
    const url = `farms/${farmId}/exatrek/assets`;

    return kanisa.get(url, {
      cancelToken: source.token,
    });
  },

  getLayerValues: async (
    farmId,
    fieldId,
    date,
    layer,
    survey,
    jobId,
    measureSettings,
    basisLayer
  ) => {
    let values = null;
    if (isSatelliteLayer(layer)) {
      const result = await WebAPIUtils.fetchFieldNdviValues(fieldId, 'sentinel2', [date]);
      if (result) {
        values = result.ndviMatrices[0].values;
      }
    } else if (isSurveyLayer(layer)) {
      if (jobId != null && !isSatelliteLayer(basisLayer)) {
        // if we have a job id, then this job was previously saved and we have to get layer values from the backup
        const result = await WebAPIUtils.getPrescriptionLayerValues(farmId, jobId, layer);
        if (result) {
          values = result.values;
        }
      } else {
        // else we get the values from the existing survey
        if (!survey) {
          return;
        }

        const result = await WebAPIUtils.getSurveyLayer(farmId, survey.surveyId, layer);
        if (result) {
          values = result.values;
        }
      }
    }

    if (values && measureSettings) {
      values = values.map((row) =>
        row.map((val) => mapInternationalSurveyValueToLocal(val, layer, measureSettings))
      );
    }
    return values;
  },

  getMultipleLayerValues: async (
    farmId: number,
    fieldId: number,
    date: Date,
    survey,
    layers: Layer[],
    jobId: number
  ) => {
    return await Promise.all([
      ...layers.map((layer) =>
        WebAPIUtils.getLayerValues(farmId, fieldId, date, layer, survey, jobId)
      ),
    ]);
  },

  getClassificationValues: async (farmId, fieldId, date, layer, survey, jobId) => {
    let values = null;
    if (isSurveyLayer(layer)) {
      if (jobId != null) {
        // if we have a job id, then this job was previously saved and we have to get layer values from the backup
        const result = await WebAPIUtils.getPrescriptionLayerValues(
          farmId,
          jobId,
          layer,
          'FI_DEMAND'
        );
        if (result) {
          values = { mappings: result.mappings, values: result.values };
        }
      } else {
        // else we get the values from the existing survey
        if (!survey) {
          return;
        }

        const result = await WebAPIUtils.getSurveyLayer(
          farmId,
          survey.surveyId,
          layer,
          'FI_DEMAND'
        );
        if (result) {
          values = { mappings: result.mappings, values: result.values };
        }
      }
    }

    return values;
  },

  /* ========================== //
  ||         PRESCRIPTION       ||
  // ========================== */

  getPrescriptionMaps: async function (farm, fields) {
    const { maps, legacy } = await kanisa.get(`farms/${farm.farmId}/vrm`);
    const { vrms } = await kanisa.get(`farms/${farm.farmId}/surveys/vrms`);
    const jobs = maps.map((job) => PrescriptionJob.fromArchive(job, fields)).filter(Boolean);

    const legacyJobs = legacy
      .map((job) => PrescriptionJob.fromArchive(job, fields, true))
      .filter(Boolean);

    const surveyJobs = vrms
      .map((job) => PrescriptionJob.fromSurveyArchive(job, fields))
      .filter(Boolean);

    return [...surveyJobs, ...jobs, ...legacyJobs];
  },

  getPrescriptionJob: function (farm, job: PrescriptionJob) {
    return kanisa.get(`farms/${farm.farmId}/surveys/vrms/${job.jobId}`);
  },

  getPrescriptionLayerValues: async (farmId, jobId, layer, type) => {
    let url = `farms/${farmId}/surveys/vrms/${jobId}/layers?layer=${layer}`;
    if (type) {
      url = url.concat(`&type=${type}`);
    }

    return kanisa.get(url, {
      headers: {
        Accept: 'application/json',
      },
    });
  },

  postSurveyPrescriptionJob: function (body: PrescriptionJobPostBody, farm) {
    return kanisa.post(`farms/${farm.farmId}/surveys/vrms`, body, {
      cancelToken: source.token,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  putPrescriptionJobSeasonid: function (farm, job: PrescriptionJob, seasonId: number) {
    const body = { seasonId: seasonId };

    if (isSurveyLayer(job.layer)) {
      return kanisa.patch(`farms/${farm.farmId}/surveys/vrms/${job.jobId}`, body);
    } else {
      return kanisa.patch(`vrm/${job.jobId}`, body);
    }
  },

  putSurveyPrescriptionJob: function (jobId, body: PrescriptionJobPutBody, farm) {
    return kanisa.put(`farms/${farm.farmId}/surveys/vrms/${jobId}`, body, {
      cancelToken: source.token,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },

  deleteSurveyPrescriptionJob: function (jobId, farmId) {
    return kanisa.delete(`farms/${farmId}/surveys/vrms/${jobId}`);
  },

  generateShapefile: function (job: PrescriptionJob, farm, survey, format) {
    const { jobName, totalPrescription, metaType, values, classifications, intervals, overrides } =
      job;
    const { farmId } = farm;

    return new Promise((resolve, reject) => {
      if (!classifications && !values) {
        reject(new Error('No values found for prescription job.'));
      } else {
        const _values = values != null ? values : classifications['values'];
        // Look through all values and find their potential prescription intervals
        const mappedValues = _values.map((row, rowIndex) => {
          return row.map((v, columnIndex) => {
            const interval = intervals.find((inter) => inter.min <= v && v <= inter.max);

            const overrideRow = overrides[rowIndex];
            if (overrideRow) {
              const overrideValue = overrideRow[columnIndex];

              if (overrideValue != null && overrideValue >= 0) {
                return overrideValue;
              }
            }

            return interval ? interval.prescription : null; // if the value is prescribed, return the prescription value, else ignore the pixel
          });
        });

        const shapefile2APIformats = [Formats.TRIMBLE_FMX, Formats.TRIMBLE_PIQ];
        const url = shapefile2APIformats.includes(format)
          ? `farms/${farmId}/surveys/vrms/shapefile2?format=${format}`
          : `farms/${farmId}/surveys/vrms/shapefile?format=${format}`;

        kanisa({
          url: url,
          method: 'POST',
          data: {
            vrmId: job.jobId,
            surveyId: survey ? survey.surveyId : '',
            name: jobName,
            totalAllocation: totalPrescription,
            metaType: metaType,
            values: mappedValues,
          },
          responseType: 'blob', // Important
        })
          .then((response) => {
            saveData(response, `${jobName}.zip`);
            resolve();
          })
          .catch((e) => {
            reject(e);
          });
      }
    });
  },

  downloadShapefileLegacy: function (farmId, jobId, jobName) {
    return new Promise((resolve, reject) => {
      kanisa({
        url: `farms/${farmId}/vrm-legacy/${jobId}/download/shp`,
        method: 'GET',
        responseType: 'blob', // Important
      })
        .then((response) => {
          saveData(response, `${jobName}.zip`);
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  downloadShapefile: function (mapId, vrmName, format) {
    return new Promise((resolve, reject) => {
      kanisa({
        url: `vrm/${mapId}/download/shp?format=${format}`,
        method: 'GET',
        responseType: 'blob', // Important
      })
        .then((response) => {
          saveData(response, `${vrmName}.zip`);
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  generateISOXMLfile: function (job: PrescriptionJob, farm, survey, format) {
    const { jobName, metaType, values, classifications, intervals, overrides } = job;
    const { farmId } = farm;

    return new Promise((resolve, reject) => {
      if (!classifications && !values) {
        reject(new Error('No values found for prescription job.'));
      } else {
        const _values = values != null ? values : classifications['values'];
        // Look through all values and find their potential prescription intervals
        const mappedValues = _values.map((row, rowIndex) => {
          return row.map((v, columnIndex) => {
            const interval = intervals.find((inter) => inter.min <= v && v <= inter.max);

            const overrideRow = overrides[rowIndex];
            if (overrideRow) {
              const overrideValue = overrideRow[columnIndex];

              if (overrideValue != null && overrideValue >= 0) {
                return overrideValue;
              }
            }

            return interval ? interval.prescription : null; // if the value is prescribed, return the prescription value, else ignore the pixel
          });
        });

        kanisa({
          url: `farms/${farmId}/surveys/vrms/isoxml?format=${format}`,
          method: 'POST',
          data: {
            vrmId: job.jobId,
            name: jobName,
            metaType: metaType,
            values: mappedValues,
          },
          responseType: 'blob', // Important
        })
          .then((response) => {
            saveData(response, `${jobName}.zip`);
            resolve();
          })
          .catch((e) => {
            reject(e);
          });
      }
    });
  },

  downloadISOXML: function (mapId, vrmName, format) {
    return new Promise((resolve, reject) => {
      let url = `vrm/${mapId}/download/isoxml`;

      if (format) {
        url += `?format=${format}`;
      }

      kanisa({
        url: url,
        method: 'GET',
        responseType: 'blob', // Important
      })
        .then((response) => {
          saveData(response, `${vrmName}.zip`);
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  getFieldsByCVR: function (cvr) {
    return kanisa.get(`fvm/fields`, {
      params: {
        cvr: cvr,
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  postPrescriptionDocumentation: function (file, farmId, body, progressCallback) {
    const data = new FormData();

    data.append('file', file, file.name);
    data.append('fileSize', file.size);
    data.append(
      'body',
      new Blob([JSON.stringify(body)], {
        type: 'application/json',
      })
    );

    return kanisa.post(`farms/${farmId}/vrms/applied`, data, {
      cancelToken: source.token,
      onUploadProgress: (ProgressEvent) => {
        progressCallback((ProgressEvent.loaded / ProgressEvent.total) * 100);
      },
    });
  },

  getPrescriptionDocumentation: function (farmId) {
    return kanisa.get(`farms/${farmId}/vrms/applied`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  deletePrescriptionDocumentation: function (id) {
    return kanisa.delete(`/vrms/applied/${id}`);
  },

  downloadAppliedDocumentation: function (id, fileName) {
    return new Promise((resolve, reject) => {
      kanisa({
        url: `/vrms/applied/${id}`,
        method: 'GET',
        responseType: 'blob', // Important
      })
        .then((response) => {
          saveData(response, fileName);
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /* ========================== //
  ||          SEASONS          ||
  // ========================== */

  getSeasons(farmId: number) {
    return kanisa.get(`farms/${farmId}/seasons`);
  },

  getSeasonFields: function (seasonId) {
    return kanisa.get(`seasons/${seasonId}/fields`, {
      cancelToken: source.token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },

  createUpdateFieldSeason: function (fieldId, seasonId, body) {
    return kanisa.put(`fields/${fieldId}/seasons/${seasonId}`, body);
  },

  updateFieldSeasons: function (body) {
    return kanisa.post(`fields/update-seasons`, body);
  },

  createSeason(farmId, name, startDate, endDate) {
    return kanisa.post(`farms/${farmId}/seasons`, {
      name: name,
      startDate: startDate,
      endDate: endDate,
    });
  },

  updateSeason: function (farmId, seasonId, body) {
    return kanisa.patch(`farms/${farmId}/seasons/${seasonId}`, body);
  },

  disableSeason: function (farmId, seasonId) {
    return kanisa.post(`farms/${farmId}/seasons/${seasonId}/disable`);
  },

  enableSeason: function (farmId, seasonId) {
    return kanisa.post(`farms/${farmId}/seasons/${seasonId}/enable`);
  },

  getFieldSeasons: function (fieldId) {
    return new Promise((resolve, reject) =>
      kanisa.get(`fields/${fieldId}/seasons`).then((result) => {
        if (result && result.seasons) {
          resolve(result.seasons);
        } else {
          reject(new Error(`failed to retrieve field seasons for fieldId: ${fieldId}`));
        }
      })
    );
  },

  removeFieldFromSeason: function (fieldId, seasonId) {
    return kanisa.delete(`fields/${fieldId}/seasons/${seasonId}`);
  },

  downloadFieldsShapefile: function (seasonId, fileName, farmName) {
    return new Promise((resolve, reject) => {
      kanisa({
        url: `seasons/${seasonId}/fields?farmName=${encodeURIComponent(farmName)}`,
        method: 'GET',
        headers: {
          Accept: 'application/zip',
        },
        responseType: 'blob',
      })
        .then((response) => {
          saveData(response, `${fileName}.zip`);
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /* ========================== //
  ||          DEVICES          ||
  // ========================== */

  updateFieldDevice: function (fieldId, deviceId) {
    return kanisa.put(`fields/${fieldId}/device-id`, {
      deviceId: deviceId,
    });
  },

  connectFieldsToDevice: function (fieldIds, deviceId) {
    return kanisa.post(`fields/connect-to-device`, {
      deviceId: deviceId,
      fields: fieldIds,
    });
  },

  /* ========================== //
  ||          CLAAS          ||
  // ========================== */

  connectToClaas: function (farmId, code, redirectUri) {
    return kanisa.post(`farms/${farmId}/integrations/claas`, {
      authorizationCode: code,
      redirectUri: redirectUri,
    });
  },

  disconnectFromClaas: function (farmId) {
    return kanisa.delete(`farms/${farmId}/integrations/claas`);
  },

  getClaasLinks: function (farmId, seasonId) {
    return kanisa.get(`farms/${farmId}/integrations/claas/links?season_id=${seasonId}`);
  },

  createClaasLinks: function (farmId, body) {
    return kanisa.post(`farms/${farmId}/integrations/claas/links`, body);
  },

  getClaasWorkBooks: function (farmId, since, until, page, count) {
    return kanisa.get(
      `farms/${farmId}/integrations/claas/workbooks?since=${since}&until=${until}&page=${page}&count=${count}`
    );
  },

  getFieldStatistics: function (seasonId) {
    return kanisa.get(`statistics/fields?season_id=${seasonId}`);
  },

  /* ========================== //
  ||      DATAINTERGRATIONS      ||
  // ========================== */

  getIntegrations: function (farmId) {
    return kanisa.get(`farms/${farmId}/integrations`);
  },

  dataIntergrationAuthentication: function (farmId, integrationTitle, user) {
    return kanisa.post(`farms/${farmId}/integrations/${integrationTitle}`, user);
  },

  disconnectFromDataIntegration: function (farmId, integrationTitle) {
    return kanisa.delete(`farms/${farmId}/integrations/${integrationTitle}`);
  },
};

export default WebAPIUtils;
