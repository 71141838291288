'use strict';

import WebAPIUtils from '../WebAPIUtils';

/* ============== //
 ||     TYPES     ||
 // ============== */

const GET_ALL_YIELD_DATA = 'fieldsense/YieldReducer/GET_ALL_YIELD_DATA';

export function getAllYieldData(farmId, fields) {
  return {
    type: GET_ALL_YIELD_DATA,
    payload: WebAPIUtils.getAllYieldData(farmId, fields),
  };
}

/* ============== //
 ||    REDUCER    ||
 // ============== */

const initState = {
  yieldData: {},
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case GET_ALL_YIELD_DATA + '_FULFILLED': {
      const payload = action.payload;
      if (payload) {
        const { yieldImagesByField } = payload;
        Object.keys(yieldImagesByField).forEach((fieldId) => {
          yieldImagesByField[fieldId].sort((a, b) => new Date(b.date) - new Date(a.date));
        });
        state = { ...state, yieldData: yieldImagesByField };
      }
      break;
    }

    case GET_ALL_YIELD_DATA + '_REJECTED': {
      state = { ...state, yieldData: {} };
      break;
    }

    default:
      break;
  }
  return state;
}
