// @flow

import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { grey } from '@material-ui/core/colors';
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Radio from '@material-ui/core/Radio/Radio';
import { PRESCRIPTION_METERING, PRESCRIPTION_UNIT } from 'js/constants/PrescriptionConstants';
import TextFormControl from 'js/components/UI-Elements/TextFormControl.jsx';
import SelectFormControl from 'js/components/UI-Elements/SelectFormControl.jsx';
import Grid from '@material-ui/core/Grid';
import { useLangFile } from 'js/context/LanguageContext';
import { MetaTypes } from 'js/components/Prescription/PrescriptionJob';
import PrescriptionAutoAdjustWarningDialog from 'js/components/Prescription/Dialogs/PrescriptionAutoAdjustWarningDialog';
import {
  DEFAULT_MAX_PRESCRIPTION,
  SQUARE_METER_COVERSION_VALUE,
} from 'js/components/Prescription/PrescriptionUtils';
import TextFormStateControl, { TEXT_FORM_STATE } from '../../../UI-Elements/TextFormStateControl';
import Button from '@material-ui/core/Button/Button';
import CachedIcon from '@material-ui/icons/Cached';
import { Box, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import ToleranceInfoDialog from '../ToleranceInfoDialog/ToleranceInfoDialog';
import { SURVEY_LAYERS } from '../../../../constants/SurveyLayers';

const validRegex = /^[a-zA-Z0-9]+$/;
const warningRegex = /^[a-zA-Z0-9æøåÆØÅ\-_()%,]+$/;

const styles = (theme) => ({
  root: {
    backgroundColor: 'white',
    color: grey['600'],
    display: 'flex',
    textAlign: 'left',
    flex: 1,
    flexDirection: 'column',
    justifyItems: 'center',
    fontSize: '0.8em',
  },
  control: {
    padding: theme.spacing(1),
  },
  label: {
    fontSize: '1rem',
  },
  buttonContainerSupplier: {
    paddingBottom: '12px',
  },
  buttonContainerManual: {
    paddingBottom: '12px',
  },
  '@media (max-width: 1024px), (max-height: 800px)': {
    control: {
      padding: [[0, theme.spacing(1) / 2]],
      paddingTop: '8px',
    },
    label: {
      fontSize: '0.75rem',
    },
    buttonContainerSupplier: {
      paddingBottom: '4px',
    },
  },
});

const PrescriptionSettings = ({
  classes,
  onError,
  onUpdateMaxPrescription,
  onUpdateUnit,
  fieldSize,
  metering,
  unit,
  metaType,
  jobName,
  limeInfo,
  onUpdateJobName,
  onUpdateMetaType,
  maxPrescription,
  onUpdateMetering,
  onUpdateLimeInfo,
  isDisabled,
  useManualLimeEfficiencyInput,
  onUpdateUseManualEfficiencyLimeInput,
  assets,
}) => {
  const LangFile = useLangFile();
  const [currentMaxPrescription, setCurrentMaxPrescription] = useState('');
  const [pendingMetaType, setPendingMetaType] = useState(null);
  const [limeSupplierEfficiency, setLimeSupplierEfficency] = useState(null);
  const [limeEfficiency, setLimeEfficiency] = useState(null);
  const [showToleranceInfo, setShowToleranceInfo] = useState(false);
  const [limeSuppliers, setLimeSuppliers] = useState([]);

  const danishLimeEfficiencyStrategy = (chosenEfficiency: number): number => {
    if (chosenEfficiency >= 80) {
      return 100;
    }

    return (chosenEfficiency / 80) * 100;
  };

  const updateLimeSupplierEfficiency = (limeSupplierEfficiency) => {
    setLimeSupplierEfficency(limeSupplierEfficiency);
    setLimeEfficiency(null);
    onError('limeSupplierEfficiency', false); // Since the dropdown doesn't handle errors well, we need to do this
    onError('manualLimeEfficiency', true);
    onUpdateLimeInfo({ efficiency: danishLimeEfficiencyStrategy(limeSupplierEfficiency) });
  };

  const updateLimeEfficiency = (limeEfficiency) => {
    setLimeSupplierEfficency(null);
    onError('limeSupplierEfficiency', true);
    setLimeEfficiency(limeEfficiency);
    onUpdateLimeInfo({ efficiency: danishLimeEfficiencyStrategy(limeEfficiency) });
  };

  useEffect(() => {
    if (metaType !== MetaTypes.LIME) {
      return;
    }

    if (limeSuppliers.length > 0) {
      return;
    }

    const efficiencyLabel = LangFile.PrescriptionSettings.limeInfo.efficiency.supplier.effect;

    // Fetch limeSuppliers from backend
    const limeInfoSuppliers = [
      {
        value: 75,
        label: `Kongerslev kalk, harpet (75% ${efficiencyLabel})`,
      },
      {
        value: 70,
        label: `Hillerslev, harpet (70% ${efficiencyLabel})`,
      },
      {
        value: 80,
        label: `Faxe kalk, knust (80% ${efficiencyLabel})`,
      },
      {
        value: 80,
        label: `Mg-kalk, 2,5% (80% ${efficiencyLabel})`,
      },
      {
        value: 85,
        label: `Mg-kalk, 5% (85% ${efficiencyLabel})`,
      },
      {
        value: 90,
        label: `Dolomitkalk, 10%, (90% ${efficiencyLabel})`,
      },
      {
        value: 38,
        label: `Carbokalk, ubehandlet (38% ${efficiencyLabel})`,
      },
      {
        value: 47,
        label: `Carbokalk, presset (47% ${efficiencyLabel})`,
      },
    ];

    setLimeSuppliers(limeInfoSuppliers);
  }, [metaType]);

  useEffect(() => {
    if (metaType === MetaTypes.LIME) {
      return;
    }
    // Potentially map from TOTAL to HA
    let max;
    switch (metering) {
      case PRESCRIPTION_METERING.HA:
        max = maxPrescription / fieldSize;
        break;
      case PRESCRIPTION_METERING.SQUARE_METER:
        max = maxPrescription / (fieldSize * SQUARE_METER_COVERSION_VALUE);
        break;
      default:
        max = maxPrescription;
    }
    setCurrentMaxPrescription(max);
  }, [maxPrescription, metering, fieldSize, metaType]);

  let maxPrescriptionEndAdornment;
  switch (unit) {
    case PRESCRIPTION_UNIT.LITER:
      maxPrescriptionEndAdornment = LangFile.PrescriptionSettings.unit.liters.short;
      break;
    case PRESCRIPTION_UNIT.PIECES_M2:
      maxPrescriptionEndAdornment = LangFile.PrescriptionSettings.unit.pieces.short;
      break;
    default:
      maxPrescriptionEndAdornment = LangFile.PrescriptionSettings.unit.kilogram.short;
  }

  let maxPrescriptionLabel;
  switch (metering) {
    case PRESCRIPTION_METERING.HA:
      maxPrescriptionLabel =
        LangFile.PrescriptionSettings.maxAllocation.maxAllocationDescriptionPerHectare;
      maxPrescriptionEndAdornment += ` / ha`;
      break;
    case PRESCRIPTION_METERING.SQUARE_METER:
      maxPrescriptionLabel =
        LangFile.PrescriptionSettings.maxAllocation.maxAllocationDescriptionPerSquareMeter;
      maxPrescriptionEndAdornment += ` / m^2`;
      break;
    default:
      maxPrescriptionLabel = LangFile.PrescriptionSettings.maxAllocation.maxAllocationDescription;
      maxPrescriptionEndAdornment += ` total`;
  }

  const handleOnError = (key) =>
    useCallback(
      (error) => {
        onError(key, error);
      },
      [onError]
    );

  const handleOnUpdateJobName = useCallback(
    (value) => {
      onUpdateJobName(value);
    },
    [onUpdateJobName]
  );

  const handleOnUpdateUnit = useCallback(
    (value) => {
      onUpdateUnit(value);
    },
    [onUpdateUnit]
  );

  const handleOnUpdateMetaType = useCallback(
    (value) => {
      if (value !== pendingMetaType) {
        if (value === MetaTypes.SPOT_SPRAYING) {
          setPendingMetaType(value);
          return;
        }
      }

      onUpdateMetaType(value);

      const metaTypeToUnit = {
        [MetaTypes.FERTILIZING]: PRESCRIPTION_UNIT.KILOGRAM,
        [MetaTypes.SEEDING]: PRESCRIPTION_UNIT.KILOGRAM,
        [MetaTypes.LIME]: PRESCRIPTION_UNIT.KILOGRAM,
        [MetaTypes.SPRAYING]: PRESCRIPTION_UNIT.LITER,
        [MetaTypes.SPOT_SPRAYING]: PRESCRIPTION_UNIT.LITER,
        [MetaTypes.LEGACY]: PRESCRIPTION_UNIT.LITER,
      };

      const unit = metaTypeToUnit[value];

      handleOnUpdateUnit(unit);

      if (value === MetaTypes.LIME) {
        onUpdateMetering(PRESCRIPTION_METERING.HA);
      }
    },
    [onUpdateMetaType, pendingMetaType, handleOnUpdateUnit]
  );

  const handleOnCancelMetaType = useCallback(() => {
    setPendingMetaType(null);
  }, []);

  const handleOnUpdateMaxPrescription = useCallback(
    (value) => {
      let max;
      switch (metering) {
        case PRESCRIPTION_METERING.HA:
          max = value * fieldSize;
          break;
        case PRESCRIPTION_METERING.SQUARE_METER:
          max = value * (fieldSize * SQUARE_METER_COVERSION_VALUE);
          break;
        default:
          max = value;
          break;
      }

      onUpdateMaxPrescription(max);
    },
    [onUpdateMaxPrescription]
  );

  const handleOnUpdateMetering = useCallback(
    (event) => {
      onUpdateMetering(event.target.value);
    },
    [onUpdateJobName]
  );

  const state = useCallback((val) => {
    if (val === null || val === undefined) {
      return {
        state: TEXT_FORM_STATE.INVALID,
        message: LangFile.PrescriptionSettings.name.errorText,
      };
    } else if (val === '') {
      return {
        state: TEXT_FORM_STATE.EMPTY,
        message: null,
      };
    } else if (validRegex.test(val)) {
      return {
        state: TEXT_FORM_STATE.VALID,
        message: null,
      };
    } else if (warningRegex.test(val)) {
      return {
        state: TEXT_FORM_STATE.WARNING,
        message: LangFile.PrescriptionSettings.name.warningText,
      };
    }
    return {
      state: TEXT_FORM_STATE.INVALID,
      message: LangFile.PrescriptionSettings.name.invalidText,
    };
  }, []);

  const DEFAULT_UNIT_OPTIONS = [
    {
      value: PRESCRIPTION_UNIT.KILOGRAM,
      label: LangFile.PrescriptionSettings.unit.kilogram.name,
    },
    {
      value: PRESCRIPTION_UNIT.LITER,
      label: LangFile.PrescriptionSettings.unit.liters.name,
    },
  ];

  const metaTypeToUnitOptions = {
    [MetaTypes.FERTILIZING]: DEFAULT_UNIT_OPTIONS,
    [MetaTypes.SEEDING]: [
      {
        value: PRESCRIPTION_UNIT.KILOGRAM,
        label: LangFile.PrescriptionSettings.unit.kilogram.name,
      },
      {
        value: PRESCRIPTION_UNIT.PIECES_M2,
        label: LangFile.PrescriptionSettings.unit.pieces.name,
      },
    ],
    [MetaTypes.LIME]: [
      {
        value: PRESCRIPTION_UNIT.KILOGRAM,
        label: LangFile.PrescriptionSettings.unit.kilogram.name,
      },
    ],
    [MetaTypes.SPRAYING]: DEFAULT_UNIT_OPTIONS,
    [MetaTypes.SPOT_SPRAYING]: DEFAULT_UNIT_OPTIONS,
    [MetaTypes.LEGACY]: DEFAULT_UNIT_OPTIONS,
    '': DEFAULT_UNIT_OPTIONS,
  };

  const unitOptions = metaTypeToUnitOptions[metaType];

  const enableUnitSelection = metaType === MetaTypes.FERTILIZING || metaType === MetaTypes.SEEDING;

  const disableLimeJob =
    assets[SURVEY_LAYERS.RT] == undefined ||
    assets[SURVEY_LAYERS.HUMUS] == undefined ||
    assets[SURVEY_LAYERS.CLAY] == undefined;

  const onlyMaxInput = (
    <Grid item xs={8} style={{ paddingBottom: 16 }}>
      <TextFormControl
        value={Number.isNaN(currentMaxPrescription) ? '' : currentMaxPrescription}
        type={'number'}
        isDisabled={isDisabled}
        required={true}
        placeholder={DEFAULT_MAX_PRESCRIPTION}
        className={classes.control}
        title={LangFile.PrescriptionSettings.maxAllocation.title}
        label={maxPrescriptionLabel}
        endAdornment={maxPrescriptionEndAdornment}
        errorText={LangFile.PrescriptionSettings.maxAllocation.errorText}
        onError={handleOnError('maxPrescription')}
        onUpdate={handleOnUpdateMaxPrescription}>
        <RadioGroup
          aria-label={'position'}
          name={'position'}
          value={metering}
          onChange={handleOnUpdateMetering}
          row>
          <FormControlLabel
            value={
              unit === PRESCRIPTION_UNIT.PIECES_M2
                ? PRESCRIPTION_METERING.SQUARE_METER
                : PRESCRIPTION_METERING.HA
            }
            control={<Radio color="primary" />}
            classes={{ label: classes.label }}
            label={
              unit === PRESCRIPTION_UNIT.PIECES_M2
                ? LangFile.PrescriptionSettings.maxAllocation.perSquareMeter
                : LangFile.PrescriptionSettings.maxAllocation.perHectare
            }
            labelPlacement="end"
          />
          <FormControlLabel
            value={PRESCRIPTION_METERING.TOTAL}
            control={<Radio color="primary" />}
            classes={{ label: classes.label }}
            label={LangFile.PrescriptionSettings.maxAllocation.entireField}
            labelPlacement="end"
          />
        </RadioGroup>
      </TextFormControl>
    </Grid>
  );

  const limeEfficiencyInput = (
    <TextFormControl
      value={limeEfficiency ? limeEfficiency : ''}
      type={'number'}
      title={LangFile.PrescriptionSettings.limeInfo.efficiency.title}
      label={LangFile.PrescriptionSettings.limeInfo.efficiency.manual.label}
      onUpdate={updateLimeEfficiency}
      placeholder={LangFile.PrescriptionSettings.limeInfo.efficiency.manual.placeholder}
      min={0}
      max={100}
      acceptZero={true}
      helperText={
        limeEfficiency && (limeEfficiency > 100 || limeEfficiency < 0)
          ? LangFile.PrescriptionSettings.limeInfo.efficiency.manual.errorText
          : ''
      }
      onError={handleOnError('manualLimeEfficiency')}
    />
  );

  const limeEfficiencySelect = (
    <SelectFormControl
      className={classes.control}
      title={LangFile.PrescriptionSettings.limeInfo.efficiency.title}
      label={LangFile.PrescriptionSettings.limeInfo.efficiency.supplier.label}
      value={limeSupplierEfficiency ? limeSupplierEfficiency : ''}
      onChange={updateLimeSupplierEfficiency}
      onUpdate={() => {}}
      placeholder={LangFile.PrescriptionSettings.limeInfo.efficiency.supplier.placeholder}
      options={limeSuppliers}
      onError={handleOnError('limeSupplierEfficiency')}
    />
  );

  const limeMin = 3000;
  const limeMax = 6000;

  const limeJobInputs = (
    <Grid container item xs={8}>
      <Grid item xs={6}>
        <TextFormControl
          value={Number.isNaN(currentMaxPrescription) ? '' : currentMaxPrescription}
          type={'number'}
          title={LangFile.PrescriptionSettings.limeInfo.max.title}
          label={LangFile.PrescriptionSettings.limeInfo.max.label}
          onChange={handleOnUpdateMaxPrescription}
          onUpdate={() => {}}
          placeholder={LangFile.PrescriptionSettings.limeInfo.max.placeholder}
          min={limeMin}
          max={limeMax}
          helperText={
            Number.isNaN(currentMaxPrescription) &&
            (currentMaxPrescription > limeMax || currentMaxPrescription < limeMin)
              ? LangFile.PrescriptionSettings.limeInfo.max.errorText
              : ''
          }
          acceptZero={true}
          onError={handleOnError('maxPrescription')}
        />
      </Grid>

      <Grid item xs={6}>
        <Box position="relative">
          <SelectFormControl
            title={LangFile.PrescriptionSettings.limeInfo.tolerance.title}
            className={classes.control}
            label={LangFile.PrescriptionSettings.limeInfo.tolerance.label}
            value={limeInfo && limeInfo.tolerance ? limeInfo.tolerance : 1}
            onChange={(value) => onUpdateLimeInfo({ tolerance: value })}
            placeholder={LangFile.PrescriptionSettings.limeInfo.max.placeholder}
            options={[
              {
                value: 0.965,
                label: `${LangFile.PrescriptionSettings.limeInfo.tolerance.options.tolerant} (AR = 0.965)`,
              },
              {
                value: 1,
                label: `${LangFile.PrescriptionSettings.limeInfo.tolerance.options.middle} (AR = 1.00)`,
              },
              {
                value: 1.035,
                label: `${LangFile.PrescriptionSettings.limeInfo.tolerance.options.sensitive} (AR = 1.035)`,
              },
            ]}
          />
          <Box position="absolute" right="4px" top="0px">
            <Tooltip title={LangFile.PrescriptionSettings.limeInfo.tolerance.tooltip}>
              <IconButton onClick={() => setShowToleranceInfo(true)}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Grid>

      <ToleranceInfoDialog open={showToleranceInfo} onClose={() => setShowToleranceInfo(false)} />

      <Grid item xs={6}>
        {useManualLimeEfficiencyInput === false && limeEfficiencySelect}

        {useManualLimeEfficiencyInput && limeEfficiencyInput}
      </Grid>

      <Grid
        container
        alignItems="flex-end"
        item
        xs={6}
        className={
          useManualLimeEfficiencyInput
            ? classes.buttonContainerManual
            : classes.buttonContainerSupplier
        }>
        <Grid item xs={12}>
          <Button
            onClick={() => onUpdateUseManualEfficiencyLimeInput(!useManualLimeEfficiencyInput)}
            variant={'outlined'}>
            {useManualLimeEfficiencyInput
              ? LangFile.PrescriptionSettings.limeInfo.efficiency.buttonTextSupplier
              : LangFile.PrescriptionSettings.limeInfo.efficiency.buttonTextManual}
            {<CachedIcon style={{ marginLeft: '12px' }} />}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Fragment>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={8} style={{ paddingBottom: 16 }}>
            <TextFormStateControl
              isDisabled={isDisabled}
              autoFocus
              value={jobName}
              required={true}
              className={classes.control}
              title={LangFile.PrescriptionSettings.name.title}
              label={LangFile.PrescriptionSettings.name.label}
              placeholder={LangFile.PrescriptionSettings.name.placeholder}
              onError={handleOnError('jobName')}
              helperText={LangFile.PrescriptionSettings.name.helperText}
              onUpdate={handleOnUpdateJobName}
              currentState={state}
              errorStates={[TEXT_FORM_STATE.INVALID, TEXT_FORM_STATE.EMPTY]}
            />
          </Grid>
          <Grid item xs={4} style={{ paddingBottom: 16 }}>
            <SelectFormControl
              disabled={isDisabled}
              className={classes.control}
              title={LangFile.PrescriptionSettings.jobType}
              label={LangFile.PrescriptionSettings.sprayTaskLabel}
              value={metaType ? metaType : ''}
              onChange={handleOnUpdateMetaType}
              options={[
                {
                  value: MetaTypes.FERTILIZING,
                  label: LangFile.PrescriptionJob.metaTypes.fertilizing,
                },
                {
                  value: MetaTypes.SPRAYING,
                  label: LangFile.PrescriptionJob.metaTypes.spraying,
                },
                {
                  value: MetaTypes.SPOT_SPRAYING,
                  label: LangFile.PrescriptionJob.metaTypes.spotSpraying,
                },
                {
                  value: MetaTypes.SEEDING,
                  label: LangFile.PrescriptionJob.metaTypes.seeding,
                },
                {
                  value: MetaTypes.LIME,
                  label: LangFile.PrescriptionJob.metaTypes.lime,
                  disabled: disableLimeJob,
                },
              ]}
            />
          </Grid>

          {metaType !== MetaTypes.LIME ? onlyMaxInput : limeJobInputs}

          <Grid item xs={4}>
            <SelectFormControl
              className={classes.control}
              disabled={!enableUnitSelection || isDisabled}
              title={LangFile.PrescriptionSettings.unit.title}
              label={LangFile.PrescriptionSettings.unit.label}
              value={unit ? unit : ''}
              onChange={handleOnUpdateUnit}
              options={unitOptions}
            />
          </Grid>
        </Grid>
      </div>

      <PrescriptionAutoAdjustWarningDialog
        pendingMetaType={pendingMetaType}
        onProceed={handleOnUpdateMetaType}
        onCancel={handleOnCancelMetaType}
      />
    </Fragment>
  );
};

export default memo(withStyles(styles)(PrescriptionSettings));

PrescriptionSettings.propTypes = {
  maxPrescription: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  metering: PropTypes.string,
  unit: PropTypes.string,
  jobName: PropTypes.string,
  metaType: PropTypes.string,
  fieldSize: PropTypes.number,
  onError: PropTypes.func,
  onUpdateMetaType: PropTypes.func,
  onUpdateMaxPrescription: PropTypes.func,
  onUpdateMetering: PropTypes.func,
  onUpdateJobName: PropTypes.func,
  onUpdateUnit: PropTypes.func,
  isDisabled: PropTypes.bool,
};

PrescriptionSettings.defaultProps = {
  // Settings default to null to avoid rendering, if the setting is not passed down from the container.
  metering: null,
  unit: null,
  jobName: null,
  task: null,
};
