'use strict';

import PropTypes from 'prop-types';

import React from 'react';

import FaceIcon from '@material-ui/icons/Face';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
  ListItemText,
  ListItem,
  ListItemIcon,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';

export default class ImpersonationDialog extends React.Component {
  CEPTU_DOMAIN = '@ceptu.com';
  CORDULUS_DOMAIN = '@cordulus.com'

  constructor(props) {
    super(props);
    this.state = {
      showImpersonationDialog: false,
      impersonationValue: '',
      username: '',
      password: '',
    };
  }

  impersonate = () => {
    this.setState({ showImpersonationDialog: true });
  };

  handleImpersonationDialogSubmit = () => {
    this.handleImpersonationDialogClose();
    this.props.onImpersonateSubmit(
      this.state.username,
      this.state.password,
      this.state.impersonationValue
    );
  };

  handleImpersonationDialogClose = () => {
    this.setState({ showImpersonationDialog: false });
  };

  isSysAdmin = () => {
    if (!this.props.email) {
      return false;
    }

    return (this.props.email.endsWith(this.CEPTU_DOMAIN) || this.props.email.endsWith(this.CORDULUS_DOMAIN));
  };

  renderImpersonationOption = () => {
    if (!this.isSysAdmin()) {
      return null;
    }

    return (
      <ListItem button onClick={this.impersonate}>
        <ListItemIcon>
          <FaceIcon />
        </ListItemIcon>
        <ListItemText primary={this.props.LangFile.ImpersonationDialog.impersonation} />
      </ListItem>
    );
  };

  renderImpersonationDialog = () => {
    if (!this.isSysAdmin()) {
      return null;
    }

    const actions = [
      <Button onClick={this.handleImpersonationDialogClose} key={'imp_d_1'}>
        {this.props.LangFile.ImpersonationDialog.cancel}
      </Button>,
      <Button
        variant="contained"
        color="primary"
        onClick={this.handleImpersonationDialogSubmit}
        key={'imp_d_2'}
      >
        {this.props.LangFile.ImpersonationDialog.impersonate}
      </Button>,
    ];

    return (
      <Dialog
        open={this.state.showImpersonationDialog}
        onClose={this.handleImpersonationDialogClose}
        maxWidth={'md'}
      >
        <DialogTitle>{this.props.LangFile.ImpersonationDialog.impersonate}</DialogTitle>
        <DialogContent style={{ minWidth: '35vw' }}>
          <TextField
            helperText={this.props.LangFile.ImpersonationDialog.usernameHint}
            label={this.props.LangFile.ImpersonationDialog.username}
            value={this.state.username}
            onChange={(event) => this.setState({ username: event.target.value })}
          />
          <br />

          <TextField
            helperText={this.props.LangFile.ImpersonationDialog.passwordHint}
            label={this.props.LangFile.ImpersonationDialog.password}
            type={'password'}
            value={this.state.password}
            onChange={(event) => this.setState({ password: event.target.value })}
          />
          <br />

          <TextField
            helperText={this.props.LangFile.ImpersonationDialog.userIdHint}
            fullWidth={true}
            label={this.props.LangFile.ImpersonationDialog.userId}
            value={this.state.impersonationValue}
            onChange={(event) => this.setState({ impersonationValue: event.target.value })}
          />
          <br />
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    );
  };

  render() {
    return (
      <div>
        {this.renderImpersonationOption()}
        {this.renderImpersonationDialog()}
      </div>
    );
  }
}

ImpersonationDialog.propTypes = {
  /** The language object, containing all texts used by the component */
  LangFile: PropTypes.object.isRequired,
  /**
   * Max Height. Defaults to 500px.
   */
  email: PropTypes.string,
  /**
   * Submit callback function.
   * @param {number} userId The User ID to impersonate
   */
  onImpersonateSubmit: PropTypes.func.isRequired,
};
