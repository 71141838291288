'use strict';

import React, { memo, useCallback, useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import NumberUtils from 'js/helpers/NumberUtils';
import useEvent from 'js/hooks/useEvent';
import Box from '@material-ui/core/Box';

import style from './PrescriptionSplitView.module.less';
import useWindowDimensions from 'js/hooks/useWindowDimensions';
import DragHandle from 'js/components/Prescription/DragHandle/DragHandle';
import GoogleMap from 'js/components/MapObjects/GoogleMap/GoogleMap';
import { usePrescriptionEditorContext } from '../PrescriptionEditorContext';
import throttle from 'lodash.throttle';

const PrescriptionSplitView = ({ overrideMode, children, bounds, numberOfAddedViews }) => {
  const [splitPos, setSplitPos] = useState(0.5);
  const [splitPosLeft, setSplitPosLeft] = useState(1 / 3);
  const [splitPosRight, setSplitPosRight] = useState(2 / 3);
  const rightDragIdentifier = 'RIGHT';
  const leftDragIdentifier = 'LEFT';
  const dragIdentifier = useRef('');

  const [leftMap, setLeftMap] = useState(null);
  const [rightMap, setRightMap] = useState(null);
  const [centerMap, setCenterMap] = useState(null);
  const [dragging, setDragging] = useState(false);
  const forceRedraw = useState({});

  const dimensions = useWindowDimensions();
  const { mapsState, selectedMap, setSelectedMap } = usePrescriptionEditorContext();

  const selectedMapColor = 'red';

  const resizeHandler = useCallback(
    (timeout = 0) => {
      setTimeout(() => {
        forceRedraw[1]({});
      }, timeout);
    },
    [forceRedraw]
  );

  const onStartDrag = () => {
    setDragging(true);
  };

  const onEndDrag = () => {
    setDragging(false);
    dragIdentifier.current = '';
  };

  const calculateDragPosition = (
    event,
    dimensions,
    padding = 12,
    minLimit = 0.15,
    maxLimit = 0.85
  ) => {
    const { width } = dimensions;
    let left = padding;
    let right = width - 2 * padding;

    let x = event.clientX;
    x = NumberUtils.limit(x, left, right);
    x = Math.abs(NumberUtils.map(x, left, right, 0, 1));

    return NumberUtils.limit(x, minLimit, maxLimit);
  };

  const throttledOnDrag = useCallback(
    throttle((event) => {
      if (dragging && dimensions) {
        const x = calculateDragPosition(event, dimensions);
        setSplitPos(x);
      }
    }, 16),
    [dragging, dimensions]
  );

  const throttledOnDragWithThreeMaps = useCallback(
    throttle((event) => {
      if (dragging && dimensions) {
        const minLimit =
          dragIdentifier.current === leftDragIdentifier
            ? 0.15
            : dragIdentifier.current === rightDragIdentifier
              ? splitPosLeft + 0.15
              : 0.15;
        const maxLimit =
          dragIdentifier.current === leftDragIdentifier
            ? splitPosRight - 0.15
            : dragIdentifier.current === rightDragIdentifier
              ? 0.85
              : 0.85;

        const x = calculateDragPosition(event, dimensions, 12, minLimit, maxLimit);
        if (dragIdentifier.current === leftDragIdentifier) {
          setSplitPosLeft(x);
        } else if (dragIdentifier.current === rightDragIdentifier) {
          setSplitPosRight(x);
        }
      }
    }, 16),
    [dragging, dimensions, splitPosLeft, splitPosRight]
  );

  const handleMouseMove = numberOfAddedViews > 0 ? throttledOnDragWithThreeMaps : throttledOnDrag;

  useEvent('resize', resizeHandler);

  useEffect(() => {
    const handleMouseMoveEvent = (event) => handleMouseMove(event);

    if (dragging) {
      window.addEventListener('mousemove', handleMouseMoveEvent);
    } else {
      window.removeEventListener('mousemove', handleMouseMoveEvent);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMoveEvent);
    };
  }, [dragging, handleMouseMove]);

  useEvent('mouseup', onEndDrag);

  useEffect(() => {
    resizeHandler(500);
  }, [overrideMode]);

  useEffect(() => {
    const defaultMap = mapsState.leftMap.identifier;

    if (numberOfAddedViews > 0 && !centerMap) {
      setSelectedMap(defaultMap);
      return;
    } else {
      setSelectedMap(defaultMap);
    }
  }, [mapsState.leftMap.identifier, centerMap, numberOfAddedViews]);

  // Calculate Dimensions and Positions
  const { leftWidth, rightWidth, centerWidth } = useMemo(() => {
    const leftWidth = numberOfAddedViews > 0 ? splitPosLeft * 100 : splitPos * 100;
    const rightWidth = numberOfAddedViews > 0 ? 100 - splitPosRight * 100 : 100 - splitPos * 100;
    const centerWidth = 100 - leftWidth - rightWidth;
    return { leftWidth, rightWidth, centerWidth };
  }, [splitPos, splitPosLeft, splitPosRight, numberOfAddedViews]);

  const leftMapBorderStyle = {
    borderColor:
      selectedMap === mapsState.leftMap.identifier && numberOfAddedViews > 0
        ? selectedMapColor
        : 'white',
    borderWidth: '2px',
    borderStyle: 'solid',
    filter:
      selectedMap === mapsState.centerMap.identifier && numberOfAddedViews > 0
        ? 'brightness(80%)'
        : 'brightness(100%)',
  };

  const centerMapBorderStyle = {
    borderColor: selectedMap === mapsState.centerMap.identifier ? selectedMapColor : 'white',
    borderWidth: '2px',
    borderStyle: 'solid',
    filter: selectedMap === mapsState.leftMap.identifier ? 'brightness(80%)' : 'brightness(100%)',
  };

  return (
    <Box display={'flex'} flexDirection={'row'} flexWrap={'nowrap'} className={style.Root}>
      <Box
        width={leftWidth + '%'}
        className={style.Map}
        style={leftMapBorderStyle}
        onClick={() => setSelectedMap(mapsState.leftMap.identifier)}>
        <GoogleMap zoom={18} optimized={false} onMapLoaded={setLeftMap} fitToBounds={bounds}>
          {leftMap && children[0](leftMap)}
        </GoogleMap>
      </Box>
      {numberOfAddedViews > 0 ? (
        <DragHandle
          movementDirection={'horizontal'}
          movementPercent={leftWidth + '%'}
          onStartDrag={() => {
            dragIdentifier.current = leftDragIdentifier;
            onStartDrag();
          }}
        />
      ) : (
        <DragHandle
          movementDirection={'horizontal'}
          movementPercent={leftWidth + '%'}
          onStartDrag={onStartDrag}
        />
      )}

      {numberOfAddedViews > 0 && (
        <>
          <Box
            width={centerWidth + '%'}
            className={style.Map}
            style={centerMapBorderStyle}
            onClick={() => setSelectedMap(mapsState.centerMap.identifier)}>
            <GoogleMap zoom={18} optimized={false} onMapLoaded={setCenterMap} fitToBounds={bounds}>
              {centerMap && children[1](centerMap)}
            </GoogleMap>
          </Box>

          <DragHandle
            movementDirection={'horizontal'}
            movementPercent={100 - rightWidth + '%'}
            onStartDrag={() => {
              dragIdentifier.current = rightDragIdentifier;
              onStartDrag();
            }}
          />
        </>
      )}

      <Box width={rightWidth + '%'} className={style.Map} border={'2px solid white'}>
        <GoogleMap zoom={18} optimized={false} onMapLoaded={setRightMap} fitToBounds={bounds}>
          {rightMap && children[2](rightMap)}
        </GoogleMap>
      </Box>
    </Box>
  );
};

export default memo(PrescriptionSplitView);
