// @flow

import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useLangFile } from 'js/context/LanguageContext';
import List from '@material-ui/core/List';
import { voidFunc } from 'js/constants/PropTypeUtils';
import { getLayerConfig } from 'js/components/Prescription/PrescriptionUtils';
import { ListItemSecondaryAction } from '@material-ui/core';

import SubList from '../SatelliteLayerList/SubList';
import MapLegendLayerListItem from '../MapLegend/MapLegendLayerListItem';
import ViewModeConstants from '../../constants/ViewModeConstants';

const YieldLayerList = ({
  selectedLayer,
  onLayerChanged,
  onReferencesChanged,
  enableMultipleSelect,
  multipleSelections,
  disableAll,
  items,
  viewMode,
  enableYield,
}) => {
  const LangFile = useLangFile();

  const isLayerSelected = useCallback(
    (layerType) => {
      if (layerType === selectedLayer) {
        return true;
      }

      if (enableMultipleSelect) {
        return multipleSelections.includes(layerType);
      }

      return layerType === selectedLayer;
    },
    [selectedLayer, enableMultipleSelect, multipleSelections]
  );

  const handleOnClick = useCallback(
    (layer) => {
      if (layer !== selectedLayer) {
        onLayerChanged(layer);
      }
    },
    [selectedLayer, onLayerChanged, enableMultipleSelect]
  );

  const handleOnCheckboxClick = useCallback(
    (layer) => {
      if (!enableMultipleSelect) {
        return;
      }
      const layers = multipleSelections.includes(layer)
        ? multipleSelections.filter((item) => item !== layer)
        : [...multipleSelections, layer];

      onReferencesChanged(layers);
    },
    [selectedLayer, onLayerChanged]
  );

  const renderEmptyState = useCallback(() => {
    return (
      <>
        {!enableYield ? (
          <span>{LangFile.YieldLayer.overviewMode.checkDataIntegrations}</span>
        ) : (
          <span>{LangFile.YieldLayer.noneApplicableYield}</span>
        )}
      </>
    );
  }, []);

  return (
    <>
      <List dense disablePadding>
        <SubList
          title={LangFile.YieldLayerList.title}
          items={items}
          renderEmptyState={renderEmptyState}
          renderItem={(layer) => (
            <MapLegendLayerListItem
              layer={layer}
              disabled={disableAll}
              enableMultipleSelect={enableMultipleSelect && layer !== selectedLayer}
              isSelected={isLayerSelected(layer)}
              onClick={handleOnClick}
              title={LangFile.YieldLayerList.title}
              onCheckboxClick={handleOnCheckboxClick}
            />
          )}
        />
      </List>
    </>
  );
};

YieldLayerList.propTypes = {
  items: PropTypes.array,
  selectedLayer: PropTypes.string,
  onLayerChanged: PropTypes.func,
  onReferencesChanged: PropTypes.func,
  enableMultipleSelect: PropTypes.bool,
  multipleSelections: PropTypes.array,
  disableAll: PropTypes.bool,
  enableYield: PropTypes.bool,
};

YieldLayerList.defaultProps = {
  items: [],
  onLayerChanged: voidFunc,
  onReferencesChanged: voidFunc,
};

export default memo(YieldLayerList);
