import React, { createContext, useState, useContext } from 'react';

const PrescriptionEditorContext = createContext();

export const PrescriptionEditorProvider = ({ children, imageDates, initialDate }) => {
  const initialMapState = {
    leftMap: {
      identifier: 'LEFT_MAP_IDENTIFIER',
      classificationsEnabled: false,
      variationsEnabled: true,
      sourceLayer: null,
      imageUrl: null,
      selectedDate: initialDate,
      multipleSelections: [],
      referenceValues: {},
    },
    centerMap: {
      identifier: 'CENTER_MAP_IDENTIFIER',
      classificationsEnabled: false,
      variationsEnabled: true,
      sourceLayer: null,
      imageUrl: null,
      selectedDate: initialDate,
      multipleSelections: [],
      referenceValues: {},
    },
  };

  const [mapsState, setMapsState] = useState(initialMapState);
  const [selectedMap, setSelectedMap] = useState('LEFT_MAP_IDENTIFIER');
  const datesWithImages = imageDates;
  const [mapPrescriptionBasedOn, setMapPrescriptionBasedOn] = useState('LEFT_MAP_IDENTIFIER');

  // Function to update map state
  const updateMapState = (mapName, key, value) => {
    setMapsState((prevState) => ({
      ...prevState,
      [mapName]: {
        ...prevState[mapName],
        [key]: value,
      },
    }));
  };

  return (
    <PrescriptionEditorContext.Provider
      value={{
        mapsState,
        updateMapState,
        selectedMap,
        setSelectedMap,
        datesWithImages,
        mapPrescriptionBasedOn,
        setMapPrescriptionBasedOn,
      }}>
      {children}
    </PrescriptionEditorContext.Provider>
  );
};

export function usePrescriptionEditorContext() {
  const context = useContext(PrescriptionEditorContext);

  if (!context) {
    throw new Error(
      'usePrescriptionEditorContext must be used within a PrescriptionEditorProvider'
    );
  }

  return context;
}
